.input {
  appearance: none;
  border: none;
  border-radius: 4em;
  padding: 2em 3em;
  background-color: var(--color__grey-4);
  font-size: 1rem;
  color: var(--color__dark-1);
  line-height: 1.25;
  @media screen and (max-width: 1023px) {
    padding: 1.5em 2em;
  }

  &::placeholder {
    color: var(--color__grey-10);
  }
}