.header {
  color: var(--color__white);
  min-height: 100vh;
  position: relative;
  padding-bottom: 8rem;

  @media screen and (max-width: 1023px) {
    padding-bottom: 3rem;
  }

  &__topbar {
    width: 100%;
    flex: 0 0 auto;
  }

  &__container {
    width: 100%;
    flex: 1 1 100%;
  }

  &__inner {
    max-width: calc(50% + 4.75rem);
    padding: 0 0 0 4.75rem;

    @media screen and (max-width: 1023px) {
      max-width: 100%;
      padding: 0;
    }
  }

  &__title {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.25;

    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__title-accent {
    font-weight: 700;
  }

  &__text {
    font-size: 2.25rem;
    margin-top: 3.5rem;
    padding-left: 2.5em;
    position: relative;

    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
      padding-left: 0;
      padding-top: 1em;
      margin-top: 1.25em;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 2em;
      top: 60%;
      left: 0;
      transform: translateY(-50%);
      background-color: var(--color__orange-2);

      @media screen and (max-width: 1023px) {
        top: 0;
        left: 0;
      }
    }
  }

  &__list {
    list-style: none;
    margin-top: 3.25rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__list-item {
    flex: 0 0 16rem;
    padding-left: 5.25rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      width: 100%;
      flex: 0 0 auto;
      padding-left: 3.5rem;
    }

    &::before {
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      top: 50%;
      left: 1.125rem;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 1023px) {
        height: 0.75rem;
        width: 0.75rem;
        left: 0.75rem;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 3.5rem;
      width: 3.5rem;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border: 1px solid var(--color__white);
      border-radius: 50%;

      @media screen and (max-width: 1023px) {
        height: 2.25rem;
        width: 2.25rem;
      }
    }

    &+& {
      margin-left: 2.5rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
        margin-top: 2rem;
      }
    }
  }

  &__list-item-title {}

  &__buttons-wrapper {
    width: 100%;
    margin-top: 3.25rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__btn {
    @media screen and (max-width: 1023px) {
      width: 100%;
      max-width: unset;
      font-size: 0.75rem;
      min-height: 5em;
    }

    &+& {
      margin-left: 2.25rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
  }

  // &__animate {
  //   position: absolute;
  //   z-index: 0;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  // }

  // &__animate-scene {
  //   width: 100%;
  //   height: 13.75rem;
  //   @include cover;
  //   background-image: -webkit-image-set(url(../img/header/header-bg-animate.png) 1x);
  //   background-image: url(../img/header/header-bg-animate.webp);
  //   position: absolute;
  //   bottom: 0;
  // }
  &__video {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: var(--color__dark-1);
  }

  &__video-inner {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    opacity: 0.5;
  }

  &.--thank-you {
    height: 100%;
    background-image: -webkit-image-set(url(../img/header/header-bg-2.webp) 1x);
    background-image: url(../img/header/header-bg-2.webp);

    .header__inner {
      width: 100%;
      padding-left: 0;
      max-width: 60rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__text-thanks {
    text-align: center;
    font-size: 3rem;

    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  &__button {
    margin-top: 2rem;
  }
}