.post-header {
  .topbar {
    color: var(--color__white);
  }

  &__inner {
    margin-top: 2rem;
    min-height: 30rem;
    position: relative;
    overflow: hidden;
    color: var(--color__white);

    @media screen and (max-width: 1023px) {
      min-height: 100vh;
      height: auto;
    }

    @media screen and (max-width: 639px) {
      min-height: unset;
    }
  }

  &__pic {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    img {
      max-width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__container.container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.75), -4px -4px 8px rgba(0, 0, 0, 0.75);
    flex-direction: column;

    @media screen and (max-width: 767px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, .75), -2px -2px 4px rgba(0, 0, 0, .75);
    }
  }

  &__title {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    max-width: 60rem;
    font-size: 4rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: auto;
    margin-right: auto;

    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
  }

  &__lead {
    padding-top: 5rem;
    flex: 0 0 auto;
    max-width: 80rem;
    font-size: 1.5rem;
    line-height: 1.75;
    font-weight: 500;
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-top: auto;
    text-align: right;

    @media screen and (max-width: 991px) {
      font-size: 1.25rem;
      line-height: 1.25;
    }
  }
}
