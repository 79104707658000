.blog-header {
  background-image: -webkit-image-set(url(../img/blog/header-bg.jpg)1x);
  background-image: url(../img/blog/header-bg.webp);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media screen and (max-width: 1023px) {
    background-image: -webkit-image-set(url(../img/blog/header-bg-mobile.jpg)1x);
    background-image: url(../img/blog/header-bg-mobile.webp);
  }

  .topbar {
    color: var(--color__white);

  }

  &__inner {
    color: #ffffff40;
    font-size: 10vh;
    padding: 1.25em 2rem;

    @media screen and (max-width: 767px) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  &__title {
    text-shadow: 1px 1px 1px var(--color__white);
    margin: 0;
  }
}