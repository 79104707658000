.price {
  height: 60vw;
  margin-top: 5rem;
  padding-top: 5rem;
  background-image: -webkit-image-set(url(../img/price/price-book.webp) 1x);
  background-image: url(../img/price/price-book.webp);
  @include bg("cover", "center top");
  padding-bottom: 7rem;
  position: relative;
  @media screen and (max-width: 1219px) {
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    height: 22rem;
    width: 11rem;
    top: 20%;
    left: 0;
    background-image: -webkit-image-set(url(../img/green-4.png) 1x);
    background-image: url(../img/green-4.webp);
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;

    @media screen and (max-width: 1699px) {
      top: 30%;
    }

    @media screen and (max-width: 767px) {
      height: 12rem;
      width: 6rem;
      top: 25%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 34rem;
    width: 22.5rem;
    top: -10%;
    right: 0;
    transform: scaleX(-100%);
    background-image: -webkit-image-set(url(../img/green.png) 1x);
    background-image: url(../img/green.webp);
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;

    @media screen and (max-width: 1399px) {
      top: -15%;
    }

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 3rem;
    height: auto;
    min-height: 100vh;
    background-position: center 25%;
    background-size: 200% auto;
    margin-top: 2rem;
  }

  &__top-wrapper {
    position: relative;
    z-index: 1;
  }

  &__title {
    font-size: 3.75rem;
    max-width: 55rem;
    font-weight: 400;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
      text-align: center;
    }
@media screen and (max-width: 349px) {
  font-size: 2rem;
}
  }

  &__title-accent {
    font-weight: 700;
  }

  &__title-text {
    margin-top: 2rem;
    font-size: 2rem;
    line-height: 1.5;
    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  &__form {
    display: block;
    box-shadow: 0 0 15.5rem rgba(40, 39, 40, 0.15);
    background-color: var(--color__white);
    padding: 0.75rem;
    border-radius: 2.5rem;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 30%;
      width: 80%;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      background-image: -webkit-image-set(url(../img/grass.png) 1x);
      background-image: url(../img/grass.webp);
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 1219px) {
      margin: 2.5rem auto 0;
    }
@media screen and (max-width: 1023px) {
  margin-top: 45vh;
}
  }

  &__form-wrapper {
    border-radius: 2rem;
    border: 1px solid var(--color__grey-9);
    padding: 2.5rem 3rem;
    @media screen and (max-width: 1023px) {
      padding: 1rem;
    }
  }

  &__form-inner {
    @media screen and (max-width: 1219px) {
      flex-direction: column;
    }
  }

  &__form-title {
    font-size: 3rem;
    font-weight: 700;
    padding-right: 2rem;
    border-right: 2px dotted rgba(83, 105, 144, 0.5);
    flex: 0 0 17rem;
    @media screen and (max-width: 1219px) {
      padding-right: 0;
      flex: 0 0 auto;
      border-right: none;
    }

    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__form-text {
    padding-left: 2rem;
    max-width: 9.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    @media screen and (max-width: 1219px) {
      max-width: unset;
      margin-top: 1em;
    }

    @media screen and (max-width: 1023px) {
      font-size: 1rem;
    }
  }

  &__radio-group {
    margin-left: 1rem;
    @media screen and (max-width: 1219px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &__radio-item {
    flex: 0 0 6.5rem;
    width: 6.5rem;
    cursor: pointer;
    @media screen and (max-width: 639px) {
      flex-basis: 4.5rem;
      width: 4.5rem;
    }
  }

  &__radio-input {
    appearance: none;
    display: block;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__radio-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0.5rem;
    width: 100%;
    text-align: center;
    border-radius: 1.25rem;
    transition: all 0.25s;
    font-size: 0.75rem;
    @media screen and (max-width: 639px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 349px) {
      font-size: 0.75rem;
    }
  }

  &__radio-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.25rem;
    width: 3.25rem;
    border-radius: 50%;
    color: var(--color__white);
    @media screen and (max-width: 639px) {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__radio-pic {
    height: 1.25rem;
    width: 1.25rem;
    object-fit: contain;
    object-position: center;
  }

  &__radio-text {
    margin-top: 0.5rem;
    white-space: nowrap;
  }

  &__radio-input:checked~&__radio-description {
    font-weight: 700;
  }

  &__radio-input:checked~&__radio-description,
  &__radio-item:hover &__radio-description {
    box-shadow: 0 7px 27px rgba(40, 40, 40, 0.1);
  }

  &__radio-group:hover &__radio-input:checked~&__radio-description:not(:hover) {
    box-shadow: none;
  }

  &__input {
    margin-left: 2rem;
    display: block;
    @media screen and (max-width: 1219px) {
      margin-left: 0;
      margin-top: 1rem;
    }
    @media screen and (max-width: 1023px) {
      max-width: 20rem;
      width: 100%;
    }
  }

  &__button {
    margin-left: 2rem;
    @media screen and (max-width: 1219px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &__checkbox-wrapper {
    margin-top: 2rem;
    @media screen and (max-width: 1219px) {
      margin-top: 1rem;
    }
  }
}