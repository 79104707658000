.bloger {
  margin-top: 6.5rem;

  &__title {
    text-align: center;
    font-size: 3.75rem;
    font-weight: 500;
    max-width: 55rem;
    margin: 0 auto;

    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__title-accent {
    font-weight: 700;
  }

  &__inner {
    margin-top: 3.25rem;
  }

  &__inner-header {
    width: 100%;
    display: block;
    overflow: hidden;
  }

  &__header-img {
    display: block;
    width: 100%;
    object-fit: contain;
    object-position: center top;
  }

  &__main {}

  &__main-top {
    --button-color: var(--color__dark-1);
    margin-top: 2rem;
    color: var(--color__dark-1);
    font-size: 1.25rem;
    display: block;

    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
    &:hover {
      --button-color: var(--color__grey-10);
      color: var(--color__grey-10);
    }

  }

  &__main-top-wrapper {
    @media screen and (max-width: 1023px) {
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__ava {
    display: block;
    height: 5rem;
    width: 5rem;
    flex: 0 0 5rem;
    border-radius: 50%;
    overflow: hidden;
  }

  &__chanel-top-wrapper {
    margin-left: 1rem;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 1rem;
      text-align: center;
    }
  }

  &__chanel-name {
    font-size: 2.25rem;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      font-size: 1.25rem;
    }
  }

  &__chanel-icon {
    height: 0.75em;
    width: 0.75em;
    display: inline-block;
  }

  &__subscr-wrapper {
    margin-left: auto;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 1rem;
      align-items: center;
      justify-content: center;
    }
  }

  &__yt-icon {
    height: 2em;
    width: 2em;
    display: inline-block;
    margin-left: 1rem;
  }

  &__chanel-data {
    margin-top: 0.5rem;
@media screen and (max-width: 991px) {
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  align-items: center;
}

    &.--small {
      font-size: 0.75em;
      margin-right: 3em;

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }
  }

  &__chanel-data-item {
    white-space: nowrap;
  }

  &__chanel-data-dot {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__subscr-link {
    display: block;
    padding: 0.75em 1em;
    background-color: var(--button-color);
    color: var(--color__white);
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all ease-in-out 0.25s;
    white-space: nowrap;
    margin-left: 1rem;

    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
      margin-left: 0;
      }
      }

      &__top-menu {
        margin-top: 1rem;

        @media screen and (max-width: 1023px) {
          display: none;
        }
      }

      &__top-menu-item {
        padding: 0.5em;
        text-transform: uppercase;
        font-weight: 500;
        opacity: 0.75;

        &.--is-active {
          border-bottom: 0.25em solid;
          opacity: 1;
  }
&:hover {
  opacity: 1;
  }
}

&__top-menu-icon {
  display: block;
  height: 1em;
  width: 1em;
}

&__video-section {
  margin-top: 2rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

  &__video-wrapper {
    flex: 0 0 40%;
    position: relative;
@media screen and (max-width: 767px) {
  width: 100%;
  flex: 0 0 auto;
}
  }

  &__video {
    width: 100%;
    }

    &__play-button {
      padding: 0.5em;
      border-radius: 50%;
      color: var(--color__white);
      background-color: #d00;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5rem;
      border: none;
      cursor: pointer;
@media screen and (max-width: 639px) {
  font-size: 3rem;
  top: 45%;
}
      &.--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__playbutton-ring {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 125%;
        width: 125%;
        display: block;
        border-radius: 50%;
        opacity: 50%;
        background-color: #d00;
        animation: pulse 1.5s ease-in-out 0s infinite alternate;

        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 120%;
          width: 120%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #dd000080;
          border-radius: 50%;
          animation: pulse 1.75s ease-in-out 0.25s infinite alternate;
        }
        }

        &__playbutton-pic {
          display: block;
          height: 1em;
          width: 1em;
          position: relative;
          z-index: 1;
          transition: all ease-in-out 0.25s;
        }

        &__play-button:hover &__playbutton-pic {
          filter: drop-shadow(0.25rem 0.25rem 0.5rem #00000080);
          transform: scale(1.05);
        }

        &__play-button:active &__playbutton-pic {
          transform: scale(0.95);
        }


        &__video-text {
          flex: 1 1 auto;
          margin-left: 2rem;

          @media screen and (max-width: 767px) {
            margin-left: 0;
            margin-top: 1rem;
          }
        }

        &__video-name {
          font-size: 1.5rem;
          font-weight: 500;
        }

        &__video-info {
          margin-top: 1rem;
          font-size: 0.75rem;
        }

        &__video-descr {
          margin-top: 1rem;
        }

        &__video-descr-text {
          &+& {
            margin-top: 0.5rem;
          }
        }
        }

        @keyframes pulse {
          from {
            height: 110%;
            width: 110%;
          }

          to {
            height: 150%;
            width: 150%;
  }
}