.testimonials {
  margin-top: 8rem;

  @media screen and (max-width: 1023px) {
    margin-top: 4rem;
  }

  .container {
    padding-left: 11rem;
    @media screen and (max-width: 1620px) {
      padding-left: 2rem;
    }
    @media screen and (max-width: 1023px) {
      padding: 0;
    }
  }

  &__title {
    max-width: 85rem;
    padding: 0 1rem;
    font-size: 3.75rem;
    font-weight: 400;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__title-accent {}

  &__part-title {
    font-weight: 400;
    font-size: 2.25rem;
  }

  &__top-descr {
    text-align: center;
    margin-top: 3.5rem;
    font-size: 2rem;
    letter-spacing: 0.75px;
    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
      margin-top: 1rem;
    }
  }

  &__inner {
    margin-top: 4rem;
    width: 100%;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
      margin-top: 1.5rem;
    }
  }

  &__video-slider {
    flex: 1 1 50%;
    max-width: 50%;
    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
      max-width: unset;
      padding: 0 1.25rem;
    }

    .swiper {
      max-width: 100%;
      width: 100%;
      padding-top: 1rem;
    }

    .swiper-wrapper {
      min-width: 1px;
      width: 100%;
      margin-top: 2.5rem;
      @media screen and (max-width: 1023px) {
        margin-top: 1rem;
      }
    }

    .swiper-slide {
      border-radius: 2.25rem;
      overflow: hidden;
      height: 31.25rem;
      background-color: var(--color__grey-7);

      iframe,
      video {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__google-wrapper {
    flex: 0 1 55%;
    margin-left: 7.5rem;
    padding: 0 11rem 0 5rem;
    position: relative;
    @media screen and (max-width: 1620px) {
      padding: 0 2rem;
      margin-left: 3rem;
    }
    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
      margin-left: 0;
      padding: 0;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 25rem;
      width: 100%;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-image: -webkit-image-set(url(../img/testimonials/testimonials-bg-map.webp) 1x);
      background-image: url(../img/testimonials/testimonials-bg-map.webp);
      background-size: contain;
      background-position: right center;
      background-repeat: no-repeat;
      @media screen and (max-width: 639px) {
        top: 4rem;
        transform: none;
        background-size: cover;
        border-radius: 1.25rem 0 0 1.25rem;
        height: 100%;
        left: 0.75rem;
      }
    }

    .swiper-wrapper {
      max-width: 30rem;
      width: 100%;
      margin-top: 2rem;
    }
  }

  &__google-slide {}

  .swiper-button-prev {
    margin-left: auto;
  }

  .swiper-button-next {
    margin-left: 1.5rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--color__orange-2);
    cursor: pointer;
    @include btn_anim(1.2, 0.9);
  }

  .swiper-button-next-icon,
  .swiper-button-prev-icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }

  .swiper-button-disabled {
    &.swiper-button-prev,
    &.swiper-button-next {
      color: var(--color__dark-2);
      opacity: 0.25;
      transform: none;
    }
  }

  .swiper-pagination {
    margin-top: 2rem;
    padding: 0.75rem 0;
  }

  .swiper-pagination-bullet {
    display: block;
    height: 1rem;
    width: 1rem;
    background-color: var(--color__grey-5);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: all ease-in-out 0.25s;

    &::before {
      content: '';
      position: absolute;
      height: 150%;
      width: 150%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: var(--color__orange-2);
      opacity: 0;
      transition: all ease-in-out 0.25s;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color__orange-2);

    &::before {
      opacity: 0.2;
    }
  }

  .swiper-pagination-bullet+.swiper-pagination-bullet {
    margin-left: 1.5rem;
  }

  &__part-title {
    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
    }
  }

  &__google-wrapper {
    .swiper {
      padding: 1rem 2rem;
      margin: 0 -2rem;
      @media screen and (max-width: 1023px) {
        padding: 1rem 1.25rem;
        margin: 0;
      }
    }

    .swiper-slide {}
  }
&__slide {
  position: relative;
  overflow: hidden;
}

&__play-button {
  padding: 0.5em;
  border-radius: 50%;
  color: var(--color__white);
  background-color: #d00;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  border: none;
  cursor: pointer;
  z-index: 2;

  @media screen and (max-width: 639px) {
    font-size: 3rem;
    top: 45%;
  }

  &.--hidden {
    opacity: 0;
    pointer-events: none;
  }
}

&__playbutton-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 125%;
  width: 125%;
  display: block;
  border-radius: 50%;
  opacity: 50%;
  background-color: #d00;
  animation: pulse 1.5s ease-in-out 0s infinite alternate;

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 120%;
    width: 120%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #dd000080;
    border-radius: 50%;
    animation: pulse 1.75s ease-in-out 0.25s infinite alternate;
  }
}

&__playbutton-pic {
  display: block;
  height: 1em;
  width: 1em;
  position: relative;
  z-index: 3;
  transition: all ease-in-out 0.25s;
}

&__play-button:hover &__playbutton-pic {
  filter: drop-shadow(0.25rem 0.25rem 0.5rem #00000080);
  transform: scale(1.05);
}

&__play-button:active &__playbutton-pic {
  transform: scale(0.95);
}

&__video-item {
  position: relative;
  z-index: 1;
}
}

.testim-item {
  padding: 1.5rem;
  background-color: var(--color__white);
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem rgba(40, 40, 40, 0.25);
  color: var(--color__dark-1);
  text-decoration: none;
  user-select: none;
  transition: all ease-in-out 0.25s;

  &:hover {
    box-shadow: 0.5rem 0.5rem 1rem rgba(40, 40, 40, 0.5);
  }

  &.--more {
    align-items: center;
    justify-content: center;
    color: var(--color__blue-2);
  }

  &__more {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  &__plus {
    padding: 0;
    margin-top: 2rem;
    height: 8rem;
    min-width: 8rem;
    width: 8rem;
    border-radius: 50%;
    border: 4px solid var(--color__blue-2);
    font-size: 3rem;
    font-weight: 500;
    line-height: 1;
    transition: all ease-in-out 0.25s;
  }

  &__plus-text {
    line-height: 1;
    padding-bottom: 0.75rem;
  }

  &.--more:hover {
    .testim-item__plus {
      background-color: var(--color__blue-2);
      color: var(--color__white);
    }
  }

  &__header {}

  &__ava {
    height: 3rem;
    width: 3rem;
    flex: 0 0 3rem;
    border-radius: 50%;
    overflow: hidden;
    display: block;
  }

  &__pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__header-content {
    margin-left: 1rem;
  }

  &__name {
    display: block;
    font-weight: 700;
  }

  &__date {
    display: block;
    margin-top: 0.5rem;
  }

  &__content {
    margin-top: 1rem;
  }

  &__stars {}

  &__text {
    margin-top: 1rem;
  }
}