.callback {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-in-out 0.25s;

  &.--is-open {
    opacity: 1;
    pointer-events: all;
  }

  &__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000090;
    backdrop-filter: blur(1rem);
  }

  &__inner {
    width: 100%;
    max-width: 40rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 1.5rem;
    background-color: var(--color__white);
    min-height: 20rem;

    @media screen and (max-width: 639px) {
      height: 80vh;
      border-radius: 0;
    }
    }

    &__form {
      width: 100%;
      text-align: center;
      // display: none;
    }

    &__header {
      font-size: 2.5rem;
      font-weight: 500;
      max-width: 27rem;
      margin: 0 auto;

      @media screen and (max-width: 639px) {
        font-size: 1.5rem;
      }
    }

    &__wrapper {
      margin-top: 2rem;
      width: 100%;

      @media screen and (max-width: 639px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__form {}

    &__input {
      @media screen and (max-width: 639px) {
        width: 100%;
        max-width: 25rem;
      }
    }

    &__submit {
      margin-left: 1rem;
      min-height: 5rem;

      @media screen and (max-width: 1023px) {
        min-height: 4rem;
      }

      @media screen and (max-width: 639px) {
        margin-left: 0;
        margin-top: 1.5rem;
        width: 100%;
        max-width: 25rem;
      }
    }

    &__final {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      width: 100%;
      display: none;
    }

    &__final-title {
      font-size: 1.5em;
    }

    &__final-text {
      margin-top: 1rem;
    }

    &__close {
      position: absolute;
      top: -2rem;
      right: -2rem;
      height: 2rem;
      width: 2rem;
      @include no-btn;

      @media screen and (max-width: 767px) {
        right: 1rem;
        top: -2.5rem;
      }

      &::after,
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 0.25rem;
        border-radius: 0.25rem;
        background-color: var(--color__white);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
}
}