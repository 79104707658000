.steps {
  margin-top: -8rem;
  position: relative;
  z-index: 1;

  &__title {
    text-align: center;
    font-size: 3.75rem;
    font-weight: 400;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
        @media screen and (max-width: 349px) {
          font-size: 2rem;
        }
  }

  &__title-accent {}

  &__inner {
    margin-top: 4rem;
  }

  &__buttons-wrapper {
    width: 100%;

    @media screen and (max-width: 1199px) {
      // overflow-x: hidden;
      min-height: 4rem;
    }
  }

  &__buttons-group {
    @media screen and (max-width: 1199px) {
      min-height: 5rem;
      overflow-x: auto;
    }
  }

  &__button {
    &+& {
      margin-left: 1rem;
    }

    &:nth-child(4) {
      @media screen and (max-width: 1023px) {
        min-width: 20em;
      }
    }
  }

  &__wrapper {
    margin-top: 4rem;
  }

  &__contacts {
    font-style: normal;
    margin-top: 4rem;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
      margin-top: 2rem;
    }
  }

  &__contacts-block {
    flex: 1 1 40%;
    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
      margin: 1rem 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &.--border {
      flex: 0 0 28%;
      border-right: 2px dotted rgba(83, 105, 144, 0.5);
      @media screen and (max-width: 1023px) {
        border-right: none;
        border-bottom: 2px dotted rgba(83, 105, 144, 0.5);
        padding-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;

      }

      &:nth-of-type(2) {
        flex: 0 0 32%;
        padding: 0 6rem;
        @media screen and (max-width: 1023px) {
          flex: 0 0 auto;
          width: 100%;
          padding: 0 0 1rem 0;
        }

      }
    }
  }


  &__contacts-title {
    font-size: 1.75rem;
    font-weight: 700;
    max-width: 18rem;
    @media screen and (max-width: 639px) {
      font-size: 1.25rem;
    }
  }

  &__dotted-text {
    color: var(--color__green-1);
    font-size: 0.75rem;
    margin-top: 1.75rem;
    padding-left: 1em;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 0.5em;
      width: 0.5em;
      top: 0.35em;
      left: 0;
      border-radius: 50%;
      background-color: currentColor;
    }
  }

  &__phone-link {
    color: var(--color__dark-1);
    font-weight: 700;
    font-size: 1.75rem;
    display: block;
    margin-top: 0.75rem;
    @media screen and (max-width: 639px) {
      font-size: 1rem;
    }

    &:hover {
      color: var(--color__orange-1);
    }
  }

  &__social {
    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
    }
  }

  &__bottom-button {}
}