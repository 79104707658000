.topbar {
  position: relative;
  z-index: 1;
  line-height: 1.75;

  &__inner {
    @media screen and (max-width: 1023px) {
      padding: 1rem 0;
    }
    @media screen and (max-width: 639px) {
      align-items: start;
    }
  }

  &__navigation {
    margin-top: 3.75rem;
  }

  &__logo-wrapper {
    @media screen and (max-width: 1023px) {
      flex-direction: column;
      max-width: 40%;
    }

    @media screen and (max-width: 639px) {
      max-width: 30%;
    }
    }

  &__logo {
    margin-top: 2.5rem;
    display: block;
    height: 3.5rem;
    width: 19.5rem;
    color: var(--color__white);
    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-top: 0;
      height: 2rem;
      object-fit: contain;
      object-position: right center;
      margin-left: auto;
    }
  }

  &__text {
    margin-top: 2.5rem;
    margin-left: 1.75rem;
    max-width: 12rem;
    font-size: 1rem;
    font-weight: 700;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 1em;
      max-width: unset;
      font-size: 0.5rem;
    }
  }

  &__rate {
    margin-left: 2.25rem;
    margin-right: auto;
    @media screen and (max-width: 1079px) {
      display: none;
    }
  }

  &__rate-ribbon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1f1f1f80;
    padding: 2.65rem 1rem;
    clip-path: path("M63.00003,80.00033l-63.00007,21.99771v-112.99843h63.00007v77.00062c0,0.00787 -0.00001,0.01574 -0.00002,0.0236z");
  }

  &__rate-inner {
    margin-left: 1.25rem;
    margin-top: 1.5rem;
    max-width: 15rem;
  }

  &__rate-stars {}

  &__rate-star {
    height: 0.75rem;
    width: 0.75rem;

    &+& {
      margin-left: 0.5rem;
    }
  }

  &__rate-text {
    font-size: 1rem;
    margin-top: 1rem;
  }

  &__rate-text-accent {
    font-weight: 700;
  }

  &__link {
    color: var(--color__white);
    text-decoration: none;
    margin-left: 1rem;
    margin-top: 1.5rem;
    max-width: 15rem;
    @include btn_anim;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  &__link-pic {
    display: block;
    height: 3rem;
    width: 3rem;
    flex-shrink: 0;
    padding: 0.75rem;
    border-radius: 50%;
    background: var(--grad__orange);
    box-shadow: inset 0 0 2.25rem rgba(255, 206, 100, 0.2);

  }

  &__link-icon {
    display: block;
    height: 100%;
    width: 100%;
  }

  &__link-text {
    margin-left: 1rem;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-skip-ink: none;
    text-decoration-color: var(--color__orange-4);
    text-decoration-thickness: 0.2em;
    font-size: 1rem;
    font-weight: 500;
  }

  &__social {
    margin-left: auto;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  &__block-title {
    font-size: 0.75rem;
    position: relative;
    @media screen and (max-width: 767px) {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      height: 0.5em;
      width: 0.5em;
      top: 50%;
      left: -1em;
      background-color: var(--color__green-1);
      border-radius: 50%;
      transform: translateY(-50%);
      animation-name: blink;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  &__block {
    margin-left: 3rem;
    @media screen and (max-width: 767px) {
      margin-left: 1.25rem;
    }
    @media screen and (max-width: 639px) {
      margin-left: 0.5rem;
    }
  }

  &__phone {
    margin-left: -0.75rem;
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    color: var(--color__white);
    word-break: keep-all;
    white-space: nowrap;
    line-height: 1;
    @media screen and (max-width: 1023px) {
      font-size: 1rem;
      margin-left: 0;
    }
    @media screen and (max-width: 349px) {
      font-size: 1.25rem;
      }

    &:hover {
      color: var(--color__orange-10);
    }
  }

  &__callback {
    margin: 0;
    @include no-btn;
    font-weight: 700;
    color: var(--color__orange-5);
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-skip-ink: none;
    text-decoration-color: var(--color__orange-5);
    text-decoration-thickness: 0.15em;
    @media screen and (max-width: 639px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 349px) {
      font-size: 0.75rem;
    }
  }

  &__menu {
    @media screen and (max-width: 1023px) {
      position: fixed;
      width: 100%;
      flex-direction: column;
      background-color: var(--color__dark-1);
      left: 0;
      top: 7rem;
      transform: translateY(150%);
      height: calc(100vh - 7rem);
      padding: 15vh 0;
      margin: 0;
      z-index: 15;
      display: none;
      transition: all ease-in-out 0.25s;
    }
  }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2rem;
    background-color: transparent;
    height: 2rem;
    width: 2.5rem;
    border: none;
    appearance: none;
    padding: 0;
    position: relative;

    @media screen and (max-width: 1023px) {
      display: flex;
    }
    @media screen and (max-width: 639px) {
      margin-left: 0.5rem;
    }
  }

  &__burger-row {
    display: block;
    height: 0.25rem;
    width: 100%;
    background-color: var(--color__orange-2);
    border-radius: 0.25rem;
    transform-origin: center;
    transition: all ease-in-out 0.25s;
  }

  &__burger.--is-open &__burger-row {
    &:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(45deg) translate(-5%, -50%);
    }

    &:nth-child(2) {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      opacity: 0;
    }

    &:nth-child(3) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(-45deg) translate(5%, -50%);
    }
  }
}

@keyframes blink {
  from {
    opacity: 0;
    box-shadow: 0 0 0 var(--color__green-1);
  }

  50% {
    box-shadow: 0 0 0 var(--color__green-1);
  }

  to {
    opacity: 1;
    box-shadow: 0 0 0.5rem var(--color__green-1);
  }
}