.--svg__check-green,.--svg__check-green-before::before,.--svg__check-green-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' class='@class' viewBox='0 0 101.03 77.43'%3E%3Cpath d='M99.2 10.69l-8.86-8.87a6.31 6.31 0 00-8.87 0L38.71 44.65 19.55 25.42a6 6 0 00-4.43-1.83 6 6 0 00-4.43 1.83l-8.87 8.86a6.31 6.31 0 000 8.87l23.6 23.59 8.86 8.87a6.31 6.31 0 008.87 0L52 66.74l47.2-47.19a6 6 0 001.8-4.43 6 6 0 00-1.83-4.43z' fill='%2313b94a'/%3E%3C/svg%3E");
}
.--svg__check-green-before::before {
    content:'';
}
.--svg__check-green-after::after {
    content:'';
}
.--svg__check-white,.--svg__check-white-before::before,.--svg__check-white-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' class='@class' viewBox='0 0 101.03 77.43'%3E%3Cpath d='M99.2 10.69l-8.86-8.87a6.31 6.31 0 00-8.87 0L38.71 44.65 19.55 25.42a6 6 0 00-4.43-1.83 6 6 0 00-4.43 1.83l-8.87 8.86a6.31 6.31 0 000 8.87l23.6 23.59 8.86 8.87a6.31 6.31 0 008.87 0L52 66.74l47.2-47.19a6 6 0 001.8-4.43 6 6 0 00-1.83-4.43z' fill='%23fff'/%3E%3C/svg%3E");
}
.--svg__check-white-before::before {
    content:'';
}
.--svg__check-white-after::after {
    content:'';
}
.--svg__check,.--svg__check-before::before,.--svg__check-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' class='@class' viewBox='0 0 101.03 77.43'%3E%3Cpath d='M99.2 10.69l-8.86-8.87a6.31 6.31 0 00-8.87 0L38.71 44.65 19.55 25.42a6 6 0 00-4.43-1.83 6 6 0 00-4.43 1.83l-8.87 8.86a6.31 6.31 0 000 8.87l23.6 23.59 8.86 8.87a6.31 6.31 0 008.87 0L52 66.74l47.2-47.19a6 6 0 001.8-4.43 6 6 0 00-1.83-4.43z' fill='%23fbaa2a'/%3E%3C/svg%3E");
}
.--svg__check-before::before {
    content:'';
}
.--svg__check-after::after {
    content:'';
}
.--svg__favorites,.--svg__favorites-before::before,.--svg__favorites-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='53' height='74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_13_2)'%3E%3Cpath d='M0 0v74.5l27-14 26 14V0H0z' fill='%23FBAA2A'/%3E%3Cpath d='M21.25 18c-2.9 0-5.25 2.36-5.25 5.274 0 2.351.919 7.932 9.962 13.573a1.023 1.023 0 001.076 0C36.08 31.206 37 25.625 37 23.274 37 20.36 34.649 18 31.75 18c-2.9 0-5.25 3.196-5.25 3.196S24.149 18 21.25 18z' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_13_2'%3E%3Cpath fill='%23fff' d='M0 0h53v74H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
.--svg__favorites-before::before {
    content:'';
}
.--svg__favorites-after::after {
    content:'';
}
.--svg__info,.--svg__info-before::before,.--svg__info-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='44' height='44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.35 0c2.948 0 4.422 2.006 4.422 4.305 0 2.871-2.56 5.527-5.893 5.527-2.792 0-4.42-1.65-4.343-4.378C21.536 3.159 23.474 0 27.35 0zm-9.07 44c-2.328 0-4.033-1.434-2.405-7.753l2.671-11.202c.464-1.791.541-2.51 0-2.51-.697 0-3.716 1.236-5.504 2.457l-1.162-1.936c5.658-4.81 12.168-7.627 14.962-7.627 2.326 0 2.713 2.8 1.551 7.106l-3.06 11.774c-.541 2.079-.31 2.796.233 2.796.698 0 2.986-.862 5.234-2.655l1.32 1.79C26.616 41.845 20.603 44 18.28 44z' fill='%23ce9127'/%3E%3C/svg%3E");
}
.--svg__info-before::before {
    content:'';
}
.--svg__info-after::after {
    content:'';
}
.--svg__link-arrow,.--svg__link-arrow-before::before,.--svg__link-arrow-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.52 5.976h10.13L8.21 9.282a.61.61 0 000 .886.668.668 0 00.92 0l4.55-4.378a.605.605 0 000-.88L9.13.531A.666.666 0 008.67.35a.666.666 0 00-.46.182.61.61 0 000 .886l3.44 3.308H1.52a.637.637 0 00-.65.625c0 .345.29.625.65.625z' fill='%23ce9127'/%3E%3C/svg%3E");
}
.--svg__link-arrow-before::before {
    content:'';
}
.--svg__link-arrow-after::after {
    content:'';
}
.--svg__progress,.--svg__progress-before::before,.--svg__progress-after::after{
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg width='36' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.5 39.5l8-39h8l-9 39h-7zM19.5 39.5l8-39h8l-9 39h-7z' fill='%23fff' fill-opacity='.3'/%3E%3C/svg%3E");
}
.--svg__progress-before::before {
    content:'';
}
.--svg__progress-after::after {
    content:'';
}