.button {
  @include no-btn("grid");
  padding: 0.5em 3.5em;
  min-height: 6rem;
  font-size: 1rem;
  border-radius: 4rem;
  color: var(--color__white);
  background-image: var(--grad__orange);
  box-shadow: 0 0.25em 0 #d7911c,
    inset 0 0 2.25em rgba(255, 206, 100, 0.2);
  text-shadow: 0 0 1.25em rgba(40, 39, 40, 0.36);
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  max-width: 22rem;
  transition: all 0.25s;
  text-transform: uppercase;
  align-items: center;
  justify-items: center;
  grid-auto-flow: column;
  grid-gap: 1em;
  @media screen and (max-width: 1023px) {
    min-height: 4em;
  }
  &.--secondary {
    background-image: none;
    background-color: var(--color__white);
    color: var(--color__dark-1);
    text-shadow: none;
    box-shadow: 0 0.25em 0 #c8c8c8;
  }
  &.--green {
    background-color: var(--color__green-4);
    background-image: none;
    box-shadow: 0 0.25em 0 var(--color__green-6);
  }
  &.--grouped {
    box-shadow: none;
    font-weight: 600;
    background-image: none;
    background-color: var(--color__grey-1);
    color: var(--color__dark-1);
    font-size: 1rem;
    text-transform: none;
    max-width: 19rem;
    @media screen and (max-width: 1023px) {
      font-size: 0.75rem;
      padding-left: 2em;
      padding-right: 2em;
    }

    &.--is-active {
      color: var(--color__white);
      background-image: var(--grad__orange);
      transform: none !important;
    }
  }

  &:hover {
    @include btn-anim;
  }

  &:active {
    box-shadow: none;
  }
  &.--p-small {
    padding: 1rem;
  }
&.--p-xs {
  min-height: auto;
  padding: 1rem 1.5rem;
}
  &:disabled {
    background-color: var(--color__grey-10);
    background-image: none;
    box-shadow: none;

    &:hover {
      transform: none !important;
      pointer-events: none !important;
      cursor: default !important;
    }
  }
&.--flare:not([disabled]) {
  position: relative;
  overflow-x: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 3rem;
    transform: skewX(-45deg);
    left: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));
    animation: flareAnimation 3s infinite ease-in-out;
  }
}
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }

  100% {
    left: 150%;
  }
}