.slide {
  margin-top: 2.25rem;
  min-height: 50vh;
    padding-bottom: 1.5rem;

  &.--finish {

    &::before {
      content: '';
      position: absolute;
      height: 50rem;
      width: 50rem;
      bottom: 0;
      right: 5rem;
      background-image: -webkit-image-set(url(../img/quiz/finish-pic.png) 1x);
      background-image: url(../img/quiz/finish-pic.webp);
      background-size: contain;
      background-position: left bottom;
      background-repeat: no-repeat;
      z-index: -1;
      @media screen and (max-width: 1129px) {
        width: 50%;
        right: 1rem;
      }
      @media screen and (max-width: 991px) {
        width: 75%;
        opacity: 0.5;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 700;
    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
    }
  }

  &__choice-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 1.5rem;
    margin-top: 2.25rem;
    width: 100%;
    @media screen and (max-width: 1249px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 1023px) {
      grid-gap: 1rem;
    }

    &.--cols-2 {
      grid-template-columns: repeat(2, 1fr);
      @media screen and (max-width: 1249px) {
        grid-template-columns: 1fr;
      }
    }

    &.--cols-1 {
      grid-template-columns: 1fr;
    }
  }

  &__check {
    display: block;
    position: relative;
    cursor: pointer;
  }

  &__check-input,
  &__choice-input {
    appearance: none;
    opacity: 0;
    display: block;
    height: 0;
    width: 0;
  }

  &__check-body {
    display: block;
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    border: 0.25rem solid var(--color__white);
    background-color: var(--color__white);
    bottom: 2rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 0.25s;
    z-index: 1;
    @media screen and (max-width: 767px) {
      height: 1.5rem;
      width: 1.5rem;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0.75rem;
      width: 0.75rem;
      @include contain;
      @media screen and (max-width: 767px) {
        height: 0.5rem;
        width: 0.5rem;
      }
    }
  }

  &__check-pic {
    height: 14rem;
    width: 100%;
    border-radius: 1.5rem;
    overflow: hidden;
    border: 1px solid transparent;
    transition: all 0.25s;
    @media screen and (max-width: 767px) {
      height: 10rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 499px) {
      height: 7rem;
    }
  }

  &__check-img {
    object-fit: cover;
    transition: all ease-in-out 0.25s;
  }

  &__check:hover &__check-img {
    transform: scale(1.1);
  }

  &__check-descr {
    display: block;
    font-size: 1.25rem;
    text-align: center;
    margin-top: 1.75rem;
    transition: all ease-in-out 0.25s;
    @media screen and (max-width: 767px) {
      font-size: 0.75rem;
    }
  }

  &__check-input:checked~&__check-body {
    background-color: var(--color__orange-2);
  }

  &__check-input:checked~&__check-pic {
    border-color: var(--color__orange-5);
  }

  &__check-input:checked~&__check-descr {
    font-weight: 700;
  }

  &__bottom {
    margin-top: auto;
    padding-top: 2rem;
    @media screen and (max-width: 639px) {
      flex-direction: column;
    }
  }

  &__bottom-text {
    max-width: 22.25rem;
  }

  &__bottom-icon {
    object-fit: contain;
    display: block;
    width: 4rem;
    height: 4rem;
  }

  &__bottom-paragraph {
    margin-left: 1.25rem;
  }

  &__choice {
    display: block;
    width: 100%;
    cursor: pointer;
    transition: all ease-in-out 0.25s;

    &.--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

&__choice-num {
  width: 100%;
}
  &__choice-body {
    display: flex;
    align-items: center;
    border-radius: 10rem;
    border: 1px solid var(--color__grey-7);
    padding: 1.25rem 1.5rem;
    transition: all ease-in-out 0.25s;
    &:hover {
      border-color: var(--color__orange-2);
      box-shadow: 0 0.25rem 4rem rgba(215, 164, 64, 0.19);
    }
  }

  &__choice-check {
    display: block;
    border-radius: 50%;
    background-color: var(--color__grey-7);
    height: 2rem;
    width: 2rem;
    flex: 0 0 2rem;
    position: relative;
    transition: all ease-in-out 0.25s;

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0.75rem;
      width: 0.75rem;
      @include contain;
    }
  }

  &__choice-text {
    font-size: 1.25rem;
    margin-left: 1em;
        @media screen and (max-width: 767px) {
          font-size: 0.75rem;
        }
  }

  &__choice-input:checked+&__choice-body {
    border-color: var(--color__orange-2);
    box-shadow: 0 0.25rem 4rem rgba(215, 164, 64, 0.19);
  }

  &__choice-input:checked+&__choice-body &__choice-check {
    background-color: var(--color__orange-2);
  }

  &__choice-input:checked+&__choice-body &__choice-text {
    font-weight: 700;
  }


  &__range-wrapper {
    width: 100%;
    margin-top: 6.5rem;
  }

  &__range-input {
    --height: 0.5rem;
    --pointer-bg: var(--color__orange-2);
    --panel-bg-fill: var(--color__orange-2);
    --panel-bg: var(--color__grey-4);
    --panel-bg-hover: var(--color__grey-7);
    --pointer-bg-hover: var(--color__orange-10);
    --pointer-border-hover: 0.25rem solid var(--color__grey-4);
  }

  &__range-title {
    margin-top: 2.5rem;
    font-size: 1.25rem;
    text-align: center;
    color: var(--color__grey-10);
  @media screen and (max-width: 1023px) {
    margin-top: 1.25rem;
  }
  }

  &__finish-wrapper {
    max-width: 30rem;
    text-align: center;
  }

  &__finish-title {
    font-weight: 700;
    font-size: 1.5rem;
  }

  &__finish-title-text {
    font-size: 3.75rem;
    display: block;
  }

  &__finish-subtitle {
    display: block;
    margin-top: 1.5rem;
  }

  &__finish-text {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 3rem;
  }

  &__radio-group {
    margin-top: 1rem;
  }

  &__radio-item {
    flex: 0 0 6.5rem;
    width: 6.5rem;
    cursor: pointer;
    &+& {
      margin-left: 0.75rem;
    }

    @media screen and (max-width: 639px) {
      flex-basis: 4.5rem;
      width: 4.5rem;
    }
  }

  &__radio-input {
    appearance: none;
    display: block;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &__radio-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0.5rem;
    width: 100%;
    text-align: center;
    border-radius: 1.25rem;
    transition: all 0.25s;
    font-size: 0.75rem;
    backdrop-filter: blur(1rem);

    @media screen and (max-width: 639px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 349px) {
      font-size: 0.75rem;
    }
  }

  &__radio-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.25rem;
    width: 3.25rem;
    border-radius: 50%;
    color: var(--color__white);
    @media screen and (max-width: 639px) {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__radio-pic {
    height: 1.25rem;
    width: 1.25rem;
    object-fit: contain;
    object-position: center;
  }

  &__radio-text {
    margin-top: 0.5rem;
    white-space: nowrap;
  }

  &__radio-input:checked~&__radio-description {
    font-weight: 700;
  }

  &__radio-input:checked~&__radio-description,
  &__radio-item:hover &__radio-description {
    box-shadow: 0 7px 27px rgba(40, 40, 40, 0.1);
    background-color: var(--color__white);
  }

  &__radio-group:hover &__radio-input:checked~&__radio-description:not(:hover) {
    box-shadow: none;
  }

  &__phone-input {
    display: block;
    margin: 1.75rem auto 0;
    width: 100%;
    max-width: 22rem;
  }

  &__submit-button {
    margin: 1.75rem auto 0;
  }

  &__checkbox-wrapper {
    margin-top: 1.75rem;
    text-align: left;
  }
&__button-next {
  &+& {
    margin-left: 1rem;

    @media screen and (max-width: 639px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
}