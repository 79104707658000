.factories {
  padding: 3.5rem 0 17.5rem;
  background-image: -webkit-image-set(url(../img/factories/factories-bg.png) 1x);
  background-image: url(../img/factories/factories-bg.webp);
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  @media screen and (max-width: 639px) {
    padding-bottom: 14rem;
  }

  &::before {
    content: '';
    position: absolute;
    height: 37rem;
    width: 20rem;
    bottom: -10rem;
    left: 0;
    display: block;
    background-image: -webkit-image-set(url(../img/green-2.png) 1x);
    background-image: url(../img/green-2.webp);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    @media screen and (max-width: 1023px) {
      height: 30rem;
      width: 15rem;
      bottom: 0;
    }

    @media screen and (max-width: 767px) {
      height: 20rem;
      width: 10rem;
      bottom: 7rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 3.75rem;
    font-weight: 400;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__inner {
    margin-top: 6rem;
    background-color: var(--color__white);
    border-radius: 1.25rem;
    overflow: hidden;
    position: relative;
  }

  &__list-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    justify-items: center;
    @media screen and (max-width: 1023px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6) {
      background-image: linear-gradient(to top, #fe990a 0%, #ffd26a 98%, #ffd26a 100%);
      color: var(--color__white);
    }

    &:nth-child(3),
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
    &:nth-child(1) {
      @media screen and (max-width: 1023px) {
        order: 1;
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: 1023px) {
        order: 2;
        flex-direction: row-reverse;
      }
    }

    &:nth-child(3) {
      @media screen and (max-width: 1023px) {
        order: 4;
      }
    }

    &:nth-child(4) {
      @media screen and (max-width: 1023px) {
        order: 3;
        flex-direction: row;
      }
    }

    &:nth-child(5) {
      @media screen and (max-width: 1023px) {
        order: 5;
      }
    }

    &:nth-child(6) {
      @media screen and (max-width: 1023px) {
        order: 6;
        flex-direction: row-reverse;
      }
    }
  }

  &__item-content {
    flex: 1 0 50%;
    padding: 2em;
    font-size: 1.5rem;
    position: relative;
    @media screen and (max-width: 1699px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 1023px) {
      font-size: 1rem;
      padding: 1em;
    }

    &::before {
      content: "";
      color: var(--color__white);
      border: 1.25rem solid transparent;
      border-left: 1.25rem solid currentColor;
      position: absolute;
      right: -2.5rem;
      top: 5rem;
    }
  }

  &__item:nth-child(2) &__item-content::before,
  &__item:nth-child(6) &__item-content::before {
    color: var(--color__orange-9);
    top: auto;
    bottom: 4rem;
  }

  &__item:nth-child(3) &__item-content::before {
    color: var(--color__orange-10);
  }

  &__item:nth-child(4) &__item-content::before {
    top: auto;
    bottom: 4rem;
  }

  &__item:nth-child(3),
  &__item:nth-child(4) {
    .factories__item-content::before {
      right: auto;
      left: -2.5rem;
      border-left-color: transparent;
      border-right-color: currentColor;
    }
  }

  &__item-num {
    font-weight: 900;
  }

  &__item-text {
    max-width: 13rem;
    margin-top: 3rem;
    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
    }
  }

  &__item-pic {
    height: 24.5rem;
    flex: 0 1 24.5rem;
    @media screen and (max-width: 1779px) {
      flex-basis: 50%;
    }

    @media screen and (max-width: 1169px) {
      height: 20rem;
    }

    @media screen and (max-width: 639px) {
      height: 14rem;
    }
  }

  &__item-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__bottom-list {
    color: var(--color__dark-1);
    margin: -2px 0 0;
    list-style: none;
    padding: 2.5rem 3.5rem;
    border-top: 4px dotted var(--color__grey-2);
    @media screen and (max-width: 1023px) {
      display: none;
    }

    &.--mobile {
      @media screen and (min-width: 1024px) {
        display: none;
      }

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.5rem;
      grid-row-gap: 1.5rem;
      padding: 1rem;
      }
  }

  &__bottom-item {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 16.75rem;
    font-size: 1.25rem;

    @media screen and (max-width: 639px) {
      font-size: 1rem;
    }

    &::before {
      display: block;
      height: 3.5rem;
      width: 3.5rem;
      flex: 0 0 3.5rem;
      background-color: var(--color__grey-2);
      background-size: 1rem 0.75rem;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      @media screen and (max-width: 1023px) {
        height: 1.5rem;
        width: 1.5rem;
        flex-basis: 1.5rem;
        background-size: 0.75rem 0.5rem;
      }
    }

    &.--thin {
      flex: 1 1 14rem;
    }
    &:last-child {
      @media screen and (max-width: 1023px) {
        grid-column: span 2;
        max-width: 70%;
      }
      }

      &+& {
        margin-left: 1rem;

        @media screen and (max-width: 1023px) {
          margin-left: 0;
  }
}
  }

  &__bottom-item-text {
    margin-left: 1.25rem;
    @media screen and (max-width: 1023px) {
      margin-left: 0.75rem;
    }
  }

  &__bottom-item-accent {}
}