.blogs {
  margin-top: 7.75rem;
  background-image: -webkit-image-set(url(../img/blogs/blogs-bg.png) 1x);
  background-image: url(../img/blogs/blogs-bg.webp);
  @include bg("cover");

  &.--single-page {
    margin: 0;
    margin-top: -3.5rem;
    padding: 2rem 0 4rem;
  }

  &__inner {
    padding-right: 11rem;

    @media screen and (max-width: 1023px) {
      padding-right: 0;
      flex-direction: column;
    }
  }

  &__subscribe {
    flex: 1 1 auto;
    text-align: center;

    @media screen and (max-width: 1023px) {
      flex: 0 0 100%;
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: 400;
    width: 100%;

    @media screen and (max-width: 1023px) {
      text-align: center;
      margin-top: 2rem;
      font-size: 1.5rem;
    }

    &.--subscribe {
      padding: 0 2rem;
      text-align: center;
      font-weight: 700;

      @media screen and (max-width: 1023px) {
        margin-top: 0;
      }
    }
  }

  &__subscribe-pic {
    margin-top: 3.25rem;
    height: 100%;
    max-height: 45.5rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 1023px) {
      width: 100%;
      max-height: unset;
    }
  }

  &__subscribe-img {}

  &__subscribe-btn {
    margin: auto auto 0;

    @media screen and (max-width: 1023px) {
      margin-top: 0;
    }
  }

  &__posts-list {
    margin-left: 8rem;
    flex: 0 0 48.5rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 3rem;
      flex: 0 0 auto;
      width: 100%;
      padding: 0 1.25rem;
    }
  }

  &__posts-item {
    margin-top: 3.75rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__posts-pic-wrapper {
    flex: 0 0 28rem;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;

    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
    }
  }

  &__posts-pic-link {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &__posts-pic {
    height: 100%;
    width: 100%;
    transition: all 0.25s;
    filter: greyscale(50%);
    opacity: 0.75;
  }

  &__posts-pic-link:hover~&__posts-pic {
    transform: scale(1.1);
    filter: greyscale(0%);
    opacity: 1;
  }

  &__posts-img {
    object-fit: cover;
    object-position: center;
  }

  &__posts-content {
    margin-left: 2.75rem;

    @media screen and (max-width: 1023px) {
      margin-left: 0;
      padding: 0;
      margin-top: 1rem;
    }
  }

  &__posts-date {
    text-transform: uppercase;
    color: var(--color__grey-7);
    font-weight: 500;
  }

  &__posts-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 2.5rem;

    @media screen and (max-width: 1023px) {
      margin-top: 0.75rem;
      font-size: 1.25rem;
    }
  }

  &__posts-lead {
    opacity: 0.5;
    line-height: 1.5;
    margin-top: 2.5rem;

    @media screen and (max-width: 1023px) {
      margin-top: 0.75rem;
    }
  }

  &__posts-link {
    display: inline-block;
    color: var(--color__orange-1);
    font-weight: 500;
    margin-top: 2.5rem;
    position: relative;

    @media screen and (max-width: 1023px) {
      margin-top: 0.75rem;
    }

    &::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 100%;
      bottom: 0;
      border-bottom: 2px dotted currentColor;
    }

    &::after {
      display: inline-block;
      width: 1em;
      height: 0.75em;
      margin-left: 0.5em;
      @include bg("contain", "center bottom");
    }
  }

  &__posts-more {
    margin-top: 4rem;
    min-width: 21rem;

    @media screen and (max-width: 1023px) {
      margin: 2rem auto 0;
    }
  }

  &__alert {
    position: fixed;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    width: 90%;
    max-width: 25rem;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: var(--color__white);
    box-shadow: var(--shadow-2);
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 0.25s;
  }

  &__alert-message {
    font-size: 1.5rem;
    font-weight: 600;

    @media screen and (max-width: 639px) {
      font-size: 1.25rem;
    }
  }

  &__alert-close {
    margin: 1rem auto 0;
    display: block;
  }
}