.cases {
  margin-top: 4rem;
  @media screen and (max-width: 1023px) {
    margin-top: 0;
  }

  &__title {
    max-width: 77rem;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 20rem;
      width: 10rem;
      top: 0;
      left: -10%;
      background-image: -webkit-image-set(url(../img/cases/leaf.png) 1x);
      background-image: url(../img/cases/leaf.webp);
      @include contain;
      @media screen and (max-width: 1023px) {
        height: 15rem;
        width: 7.5rem;
        left: 30%;
        top: 35%;
      }

      @media screen and (max-width: 991px) {
        left: 25%;
      }

      @media screen and (max-width: 639px) {
        left: 20%;
        top: 30%;
        height: 10rem;
        width: 5rem;
      }

      @media screen and (max-width: 539px) {
        left: 15%;
      }

      @media screen and (max-width: 439px) {
        left: 5%;
      }
    }
  }

  &__count {
    font-size: 16rem;
    font-weight: 700;
    min-width: 2em;
    background-image: -webkit-image-set(url(../img/cases/cases-counter-bg.jpg) 1x);
    background-image: url(../img/cases/cases-counter-bg.webp);
    background-clip: text;
    color: transparent;
    text-align: right;
    @include cover;
    @media screen and (max-width: 639px) {
      font-size: 11.5rem;
      text-align: center;
      max-width: 100%;
      min-width: unset;
    }
  }

  &__title-text {
    font-size: 3.75rem;
    margin-left: 1em;
    font-weight: 400;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
      margin-left: 0;
      margin-top: 1rem;
      text-align: center;
      padding: 0 2rem;
    }

    @media screen and (max-width: 349px) {
      font-size: 2rem;
    }
  }

  &__title-accent {
    font-weight: 700;
  }

  &__filter-wrapper {
    max-width: 100%;
    margin-top: 4rem;
    @media screen and (max-width: 1199px) {
      overflow-x: hidden;
    }
    @media screen and (max-width: 1023px) {
      margin-top: 2rem;
    }
  }

  &__filter {
    max-width: 100%;
    width: fit-content;
    margin: 0 auto;

    @media screen and (max-width: 1119px) {
      overflow-x: auto;
      justify-content: flex-start;
      min-height: 5rem;
      }
  }

  &__filter-btn {
    display: block;
    min-width: 15.5rem;
    @media screen and (max-width: 1249px) {
      min-width: 11rem;
      padding: 1em;
      min-height: 3.5em;
    }
    @media screen and (max-width: 639px) {
      min-width: 7rem;
    }

    &+& {
      margin-left: 1.25rem;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem;
    margin-top: 3.75rem;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;

    @media screen and (max-width: 1880px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 87.5rem;
    }

    @media screen and (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 639px) {
      grid-template-columns: 1fr;
    }
  }

  &__slide {
    border-radius: 1.5rem;
    overflow: hidden;
    transition: all 0.25s;
    // display: none;
    height: 17.75rem;
    width: 100%;
    display: block;
    cursor:zoom-in;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 12.5rem;
    }

    @media screen and (max-width: 639px) {}

    &:hover {
      transform: scale(1.1);
    }

    &:nth-child(n+9) {
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__slide-preview {
    display: block;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
      display: block;
    }
  }

  &__img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__button-wrapper {
    margin-top: 3.25rem;
  }

  &__more-btn.button {
    max-width: none;
  }
}