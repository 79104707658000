.contacts {
  margin-top: 6rem;
  font-style: normal;
  padding-bottom: 4.5rem;

  &__inner {
    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &__col {
    flex: 1 1 25%;

    @media screen and (max-width: 1023px) {
      flex: 0 0 auto;
      width: 100%;
    }

    &.--photos {
      flex: 0 1 auto;

      @media screen and (max-width: 1023px) {
        flex: 0 0 auto;
        width: 100%;
      }

      .contacts__col-title {
        @media screen and (max-width: 1023px) {
          text-align: center;
          width: 100%;
        }
        }
        }

    &+& {
      margin-left: 4.75rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
        margin-top: 2.5rem;
      }
    }
    // &.--favorites {
    //   text-align: center;
    //   padding: 7.75rem 2rem 2rem;
    //   border: 2px dashed var(--color__orange-2);
    //   border-radius: 1.5rem;
    //   position: relative;

    //   &::before {
    //     position: absolute;
    //     display: block;
    //     left: 0;
    //     right: 0;
    //     margin: 0 auto;
    //     top: -2px;
    //     height: 4.75rem;
    //     width: 3.25rem;
    //     @include bg("contain", "center top");
    //   }
    // }
  }

  &__col-title {
    font-weight: 700;
    font-size: 2.25rem;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__col-subtitle {
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: 2.75rem;
    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
      margin-top: 1rem;
    }
  }

  &__col-address {
    margin-top: 1em;
    font-size: 1.5rem;
    display: inline-block;
    color: var(--color__orange-1);
    @media screen and (max-width: 1023px) {
      font-size: 1rem;
    }
  }

  &__cities-subtitle {
    margin-top: 2.75rem;
    font-weight: 700;
    font-size: 1rem;
    display: block;
    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
    }
  }

  &__cities-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2.75rem;
    grid-row-gap: 0.25rem;
    @media screen and (max-width: 1023px) {
      grid-column-gap: 1rem;
    }
  }

  &__list-item {}

  &__map {
    border-radius: 1.25rem;
    overflow: hidden;
    width: 100%;
    display: block;
    margin-top: 2.75rem;
    @media screen and (max-width: 1023px) {
      border-radius: 0.75rem;
      margin-top: 1rem;
      min-height: 50vh;
    }
  }

  &__group {
    margin-top: 5rem;
    @media screen and (max-width: 1023px) {
      margin-top: 2rem;
    }
  }

  &__suptext {
    font-size: 0.75em;
    display: block;
    padding-left: 1em;
    position: relative;
    margin-top: 1rem;

    &::before {
      content: '';
      position: absolute;
      height: 0.5em;
      width: 0.5em;
      top: 0.5em;
      transform: translateY(-25%);
      left: 0;
      border-radius: 50%;
      background-color: var(--color__green-1);
            animation-name: blink;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
    }
  }

  &__phone {
    color: var(--color__dark-1);
    font-size: 1.75rem;
    font-weight: 700;
    text-decoration: none;
    margin-top: 1.25rem;
    display: inline-block;
    @media screen and (max-width: 1079px) {
      font-size: 1.25rem;
    }

    &:hover {
      color: var(--color__orange-1);
    }
  }

  &__callback {
    @include no-btn;
    display: block;
    margin-top: 1.25rem;
    line-height: 1.5;
    font-weight: 700;
    color: var(--color__orange-1);
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  &__group-title {
    font-size: 1.75rem;
    font-weight: 700;
    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
    }
  }

  &__social.social {
    justify-content: flex-start;
    margin-top: 1rem;

  }

  &__email {
    color: var(--color__dark-1);
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    margin-top: 1.25rem;
    display: inline-block;
    @media screen and (max-width: 1023px) {
      font-size: 1rem;
    }

    &:hover {
      color: var(--color__orange-1);
    }
  }

  &__illustration {
    display: block;
    margin-top: 2rem;
    width: 100%;
    position: relative;
    border-radius: 1.25rem;
    overflow: hidden;
@media screen and (max-width: 1023px) {
  height: auto;
  width: 100%;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
  }

  &__pic {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
  }

  &__img {}

  &__illustration-text {
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    width: 100%;
    text-align: center;
    color: var(--color__white);
    font-size: 1.25rem;
    font-weight: 700;
    text-shadow: 1px 1px 0.5em var(--color__black),
    -1px -1px 0.5em var(--color__black);
  }

  // &__favorites-title {
  //   font-size: 2rem;
  //   font-weight: 700;
  // }

  // &__favorites-text-top {
  //   margin-top: 2.25rem;
  //   font-size: 1.5rem;
  //   font-weight: 500;
  // }

  // &__favorites-button {
  //   margin: 2.25rem auto 0;
  // }

  // &__favorites-text-bottom {
  //   font-size: 1.25rem;
  //   margin: 2.25rem auto 0;
  //   max-width: 10.5rem;
  // }

  // &__favorites-text-keys {
  //   margin-top: 1rem;
  //   font-size: 2.25rem;
  //   font-weight: 700;
  // }
}