.menu {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    &+& {
      margin-left: 1em;
    }
  }

  &__link {
    text-transform: uppercase;
    color: var(--color__white);
    transition: all 0.25s;
    font-size: 1.25rem;
    font-weight: 500;

    &:hover {
      color: var(--color__orange-10);
    }
  }
}