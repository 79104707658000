.product {
  color: var(--color__dark-1);
  scroll-margin-top: 3rem;
  padding: 2rem;
  border-radius: 0.75rem;
  transition: all ease-in-out 0.25s;
  transition-delay: 0.25s;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 1023px) {
    padding: 1rem;
    flex-direction: column;
    border-radius: 0.5rem;
    justify-content: flex-start;
  }

  @media screen and (max-width: 639px) {
    padding: 0.5rem;
  }

  &.--selected {
    background-color: var(--color__white);
    box-shadow: -1rem 2rem 4rem rgba(51, 58, 69, 0.1);
  }

  &__pic {
    height: 12.5rem;
    width: 12.5rem;
    flex: 0 0 12.5rem;
    border-radius: 50%;
    overflow: hidden;
    opacity: 0.75;
    transition: all ease-in-out 0.25s;
    transition-delay: 0.25s;
    @media screen and (max-width: 1023px) {
      height: 5rem;
      width: 5rem;
      flex: 0 0 5rem;
    }
  }

  &:hover &__pic,
  &.--selected &__pic {
    opacity: 1;
    border-radius: 1rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 8rem;
      flex: 0 0 8rem;
      border-radius: 0.5rem;
    }
  }

  &__img {
    object-fit: cover;
  }

  &__content {
    margin-left: 3rem;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &__number {
    font-size: 1.5rem;
    opacity: 0.25;
  }

  &__name {
    margin-top: 0.5rem;
    font-size: 2.25rem;
    font-weight: 700;
@media screen and (max-width: 1023px) {
  font-size: 1.25rem;
}
  }

  &__text {
    margin-top: 0.5rem;
    font-size: 1.25rem;
@media screen and (max-width: 1023px) {
  font-size: 1rem;
}
  }
}