.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.--just-space {
  justify-content: space-between;
}

.--just-center {
  justify-content: center;
}

.--just-end {
  justify-content: flex-end;
}

.--align-str {
  align-items: stretch;
}

.--align-center {
  align-items: center;
}

.--align-end {
  align-items: flex-end;
}

.--dir-col {
  flex-direction: column;
}

html {
  font-size: 16px;
  @media screen and (max-width: 1779px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1667px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1439px) {
    font-size: 13px;
  }

  @media screen and (max-width: 1349px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 16px;
  }
  @media screen and (max-width: 349px) {
    font-size: 12px;
  }
}

html,
body {
  min-height: 100vh;
  position: relative;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  font-family: var(--base-font), Arial, Helvetica, sans-serif;
  color: var(--color__dark-1);
  display: flex;
  flex-direction: column;
  }

  .header,
  .footer {
    flex: 0 0 auto;
    width: 100%;
  }

  .main {
    flex: 1 1 100%;
    width: 100%;
}

picture {
  display: block;

  img {
    display: block;
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}
button,a,input{
  transition: all 0.25s;
  &:focus{
    outline: transparent;
  }
}
a{
  text-decoration: none;
}
.container {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  &.--full {
    padding: 0 2rem;
    max-width: 1752px;
    @media screen and (max-width: 767px) {
      padding: 0 1.25rem;
    }
  }

  &.--m {
    padding: 0 2rem;
    max-width: 1768px;
    @media screen and (max-width: 767px) {
      padding: 0 1.25rem;
    }
  }

  &.--fm {
    padding: 0 2rem;
    max-width: 1632px;
    @media screen and (max-width: 767px) {
      padding: 0 1.25rem;
    }
  }
  &.--s {
    padding: 0 2rem;
    max-width: 1264px;

    @media screen and (max-width: 767px) {
      padding: 0 1.25rem;
    }
  }
}
.main {
  padding-top: 3.25rem;
  background-color: var(--color__white);
  max-width: 100vw;
  overflow: hidden;
}