.mobile-social {
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  display: block;
  z-index: 10;

  @media screen and (max-width: 639px) {
    position: sticky;
    left: 0;
    bottom: 0;
    }

    &__wrapper {
      display: none;

      &.--desktop {
        @media screen and (min-width: 640px) {
          display: block;
        }
      }

      &.--mobile {
        @media screen and (max-width: 639px) {
          display: flex;
        }
      }
  }

  &__btn {
    color: var(--color__blue-3);
    display: block;
    height: 3rem;
    width: 3rem;
    background-color: transparent;
    padding: 0.5rem;
    border: 0.25rem solid var(--color__blue-3);
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      display: block;
      height: 0.25rem;
      width: 85%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color__white);
      border-radius: 1rem;
      opacity: 0;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover {
      color: var(--color__white);
      background-color: var(--color__blue-3);
      opacity: 1;
    }
  }

  &__btn-icon {
    display: block;
    height: 100%;
    width: 100%;
    transition: all ease-in-out 0.25s;
  }

  &.--is-open &__btn {
    color: var(--color__white);
    background-color: #13a6e680;
    opacity: 1;

    &:hover {
      background-color: var(--color__blue-3);
    }

    &::after,
    &::before {
      opacity: 1;
    }
  }

  &.--is-open &__btn-icon {
    opacity: 0;
  }

  &__list {
    position: absolute;
    flex-direction: column;
    top: -13rem;
    left: 0;
    pointer-events: none;
  }

  &.--is-open &__list {
    pointer-events: all;
  }

  & .social__link {
    margin-left: 0;
    margin-top: 1rem;
    transform: translateX(-500%);
  }

  &.--is-open .social__link {
    &:nth-child(1) {
      animation: showLink 0.25s ease-in-out 150ms 1 normal forwards;
    }

    &:nth-child(2) {
      animation: showLink 0.25s ease-in-out 100ms 1 normal forwards;
    }

    &:nth-child(3) {
      animation: showLink 0.25s ease-in-out 50ms normal forwards;
    }
  }
&__viber,
&__modal {
  flex: 0 0 50%;
  display: grid;
  grid-template-columns: 20% 80%;
  color: var(--color__white);
  border: none;
  padding: 0.75rem 0.5rem;
  align-items: center;
  text-align: center;
}

&__viber {
  background-color: var(--color__violet);
}

&__modal {
  background-color: var(--color__green-1);
}

&__icon {
  display: block;
  height: 1.5em;
  width: 1.5em;
  margin-left: auto;
}
}

@keyframes showLink {
  to {
    transform: translateX(0);
  }
}

@keyframes hideLink {
  to {
    transform: translateX(-500%);
  }
}