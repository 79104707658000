@include font-face("TTNorms-Black", "TTNorms-Black", 400);
@include font-face("TTNorms-BlackItalic", "TTNorms-BlackItalic", 400);
@include font-face("TTNorms-Bold", "TTNorms-Bold", 400);
@include font-face("TTNorms-BoldItalic", "TTNorms-BoldItalic", 400);
@include font-face("TTNorms-ExtraBold", "TTNorms-ExtraBold", 400);
@include font-face("TTNorms-ExtraBoldItalic", "TTNorms-ExtraBoldItalic", 400);
@include font-face("TTNorms-ExtraLight", "TTNorms-ExtraLight", 400);
@include font-face("TTNorms-ExtraLightItalic", "TTNorms-ExtraLightItalic", 400);
@include font-face("TTNorms-Heavy", "TTNorms-Heavy", 400);
@include font-face("TTNorms-HeavyItalic", "TTNorms-HeavyItalic", 400);
@include font-face("TTNorms-Italic", "TTNorms-Italic", 400);
@include font-face("TTNorms-Light", "TTNorms-Light", 400);
@include font-face("TTNorms-LightItalic", "TTNorms-LightItalic", 400);
@include font-face("TTNorms-Medium", "TTNorms-Medium", 400);
@include font-face("TTNorms-MediumItalic", "TTNorms-MediumItalic", 400);
@include font-face("TTNorms-Regular", "TTNorms-Regular", 400);
@include font-face("TTNorms-Thin", "TTNorms-Thin", 400);
@include font-face("TTNorms-ThinItalic", "TTNorms-ThinItalic", 400);
