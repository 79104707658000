.post {
  background-color: var(--color__dark-1);

  .post__main {
    padding: 0;
  }

  &__body {
    font-size: 1.25rem;
    line-height: 1.4;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }

    &>* {
      width: 100%;
    }

    *+p,
    *+figure {
      margin-top: 1.5em;
    }

    a {
      text-decoration: underline;
      color: var(--color__orange-11);

      &:hover {
        color: var(--color__green-9);
      }
    }

    figure {
      text-align: center;
      max-height: 75vh;

      img {
        max-width: 100%;
        object-fit: contain;
      }

      figcaption {
        text-align: center;
        font-size: 0.75em;
        color: var(--color__blue-1);
      }

      &[data-orient="left"] {

        img {
          max-width: 50%;
          object-fit: contain;
        }

        text-align: right;
      }

      &[data-orient="right"] {

        img {
          max-width: 50%;
          object-fit: contain;
        }

        text-align: left;
      }
    }

    ul,
    ol {
      margin-left: 0;
      padding-left: 1.5ch;

      & ul,
      ol {
        padding-left: 1ch;
        font-size: 0.75em;
      }
    }

    blockquote {
      margin-top: 1.5em;
      padding: 1em 0 1em 1em;
      border-left: 2px solid var(--color__orange-2);
      position: relative;
      font-size: 1.25em;

      &::before {
        content: '«»';
        position: absolute;
        font-size: 8em;
        top: 0;
        transform: translateY(-50%) rotate(15deg);
        line-height: 1;
        right: -2rem;
        opacity: 0.25;
      }
    }

    iframe {
      display: block;
      margin: 1em auto 0;
      max-width: 100%;
    }
  }
}