.bullets {
  padding-top: 4rem;

  @media screen and (max-width: 639px) {
    padding-top: 1rem;
  }

  &__title {
    font-size: 3.75rem;
    font-weight: 400;
    text-align: center;
    max-width: 65rem;
    padding: 0 2rem;
    margin: 0 auto;

    @media screen and (max-width: 1023px) {
      font-size: 1.25rem;
    }
  }

  &__map {
    background-image: -webkit-image-set(url(../img/bullets/bullets-bg.png) 1x);
    background-image: url(../img/bullets/bullets-bg.webp);
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 65vw;
    position: relative;
    z-index: 0;
  }

  &__item-wrapper {
    position: absolute;
    padding-top: 2.5rem;
    margin-top: -2.5rem;

    &:nth-of-type(1) {
      left: 10%;
      top: 40%;
    }

    &:nth-of-type(2) {
      left: 23%;
      top: 27%;
    }

    &:nth-of-type(3) {
      left: 38%;
      top: 78%;
    }

    &:nth-of-type(4) {
      left: 51%;
      top: 78%;
    }

    &:nth-of-type(5) {
      left: 38%;
      top: 45%;
    }

    &:nth-of-type(6) {
      left: 70%;
      top: 55%;
    }

    &:nth-of-type(7) {
      left: 60.5%;
      top: 32%;
    }

    &:nth-of-type(8) {
      left: 84.5%;
      top: 30%;
    }
  }

  &__item {
    @include no-btn;
    display: block;
    padding: 0.5em;
    font-size: 2rem;
    color: var(--color__white);
    font-weight: 700;
    position: relative;
    z-index: 1;
    opacity: 0.5;
    user-select: none;

    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: .75rem;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: 1;
    }

    &::before {
      height: 2.5em;
      width: 2.5em;
      background-color: #fff;
    }

    &::after {
      height: 3em;
      width: 3em;
      background-color: rgba(255, 255, 255, 0.35);
    }
  }

  &__item-plus {
    background-image: var(--grad__orange);
    line-height: 1;
    height: 2em;
    width: 2em;
    text-align: center;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item:hover {
    opacity: 1;
    pointer-events: all;
  }

  &__item-link {
    color: var(--color__dark-1);
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(0.25rem);
    padding: 0.5em;
    border-radius: 0.25em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 8rem;
    text-align: center;
    transition: all ease-in-out 0.25s;
    opacity: 0;
    pointer-events: none;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &__item-wrapper.--is-active &__item-link,
  &__item-wrapper:hover &__item-link {
    opacity: 1;
    pointer-events: all;
  }
}