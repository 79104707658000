.footer {
  padding: 1.25rem 0 2rem;
  background-color: var(--color__dark-3);
  color: var(--color__white);
  font-size: 0.75rem;
  font-weight: 500;

  &__inner {
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__logo {
    color: var(--color__white);
    width: 19.5rem;
    height: 3.5rem;
  }

  &__text {
    max-width: 10rem;
    margin-left: 2.5rem;
    margin-top: 1.5rem;
    @media screen and (max-width: 991px) {
      margin-left: 0;
      max-width: unset;
    }
  }

  &__copy {
    margin-left: auto;
    text-align: center;
    max-width: 8.5rem;
    margin-top: 1.5rem;
    @media screen and (max-width: 991px) {
      margin-right: auto;
      max-width: unset;

    }
  }

  &__link {
    margin-left: auto;
    color: var(--color__orange-8);
    text-decoration: underline;
    margin-top: 1.5rem;
    @media screen and (max-width: 991px) {
      margin-right: auto;
      max-width: unset;

    }
  }
}