.quiz {
  margin-top: 6.75rem;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, var(--color__grey-9) 100%);
  padding-bottom: 3.25rem;
  position: relative;
  scroll-margin-top: 6rem;
  @media screen and (max-width: 1023px) {
    margin-top: 2rem;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 33.5rem;
    width: 21.75rem;
    bottom: -30%;
    left: 0;
    background-image: -webkit-image-set(url(../img/green.png) 1x);
    background-image: url(../img/green.webp);
    @include cover;
    z-index: 1;
    @media screen and (max-width: 1199px) {
      width: 16rem;
      height: 26rem;
    }

    @media screen and (max-width: 1023px) {
      width: 10rem;
      height: 16rem;
      bottom: -5%;
    }
  }

  &__header {
    margin: 0 auto;
    max-width: 75rem;
    text-align: center;
  }

  &__header-title {
    font-size: 3.75rem;
    @media screen and (max-width: 1023px) {
      font-size: 1.5rem;
    }
  }

  &__header-text {
    margin: 2.75rem auto 0;
    font-size: 2rem;
    max-width: 70rem;
    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
      font-size: 1.25rem;
    }
  }

  &__inner {
    box-shadow: -1rem 2rem 4.25rem rgba(51, 58, 69, 0.1);
    background-color: var(--color__white);
    background-image: -webkit-image-set(url(../img/quiz/quiz-inner-bg.png) 1x);
    background-image: url(../img/quiz/quiz-inner-bg.webp);
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 5.5rem 1.75rem 0 7.5rem;
    margin-top: 4.5rem;
    border-radius: 2.5rem;
    position: relative;
    z-index: 1;
    min-height: 62rem;
    @media screen and (max-width: 1023px) {
      padding: 1rem;
      margin-top: 2rem;
      background-image: none;
      border-radius: 1.25rem;
      min-height: 50rem;
    }

    @media screen and (max-width: 639px) {
      padding: 0.5rem;
      min-height: 30rem;
    }
  }

  &__slider {
    flex: 0 0 58rem;
    @media screen and (max-width: 1249px) {
      flex: 1 1 35rem;
    }
    @media screen and (max-width: 1023px) {
      flex: 1 1 auto;
    }
  }

  &__slides-item {
    display: none;
    min-height: 47rem;
    @media screen and (max-width: 1023px) {
      min-height: 10rem;
    }

    &.--is-active {
      display: flex;
    }
  }

  &__content {
    flex: 0 1 auto;
    position: relative;
    margin-left: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    &::before {
      content: '';
      position: absolute;
      height: 126px;
      width: 120%;
      bottom: 0;
      right: -1.75rem;
      background-image: -webkit-image-set(url(../img/quiz/quiz-grass.png) 1x);
      background-image: url(../img/quiz/quiz-grass.webp);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right bottom;
      z-index: 1;
    }
  }

  &__content-title {
    text-align: center;
    padding: 0 1.5rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__content-item {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    white-space: nowrap;

    &+& {
      margin-top: 1rem;
    }
  }

  &__content-text {
    margin: 0 0.5rem;
  }

  &__content-accent {}

  &__content-pic {
    margin: 0 0.5rem;
    min-height: 3rem;
    min-width: 3rem;
  }

  &__content-img {}

  &__employer {
    position: relative;
    margin-top: auto;
    @media screen and (max-width: 1249px) {
      margin-top: auto;
    }
  }

  &__employer-text {
    position: absolute;
    left: 0;
    top: 8rem;
    text-align: right;
    max-width: 10rem;
  }

  &__employer-name {
    display: block;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__employer-pic {
    height: 35rem;
    width: 35rem;
    margin-left: auto;
  }

  &__employer-img {
    object-position: right bottom;
  }
  &__slider-progress {
    height: 2.5rem;
    border-radius: 1.25rem;
    background-color: var(--color__grey-4);
    overflow: hidden;
    position: relative;
    --progress: 1;
  }

  &__progress-descr {
    position: relative;
    z-index: 2;
  }

  &__slider-progress-body {
    will-change: background-position;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 1.25rem;
    height: 100%;
    max-width: 100%;
    width: calc((var(--progress)/6) * 100%);
    background-color: var(--color__orange-2);
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-position: 0 0;
    transition: width ease-in-out 0.5s;
    animation: progressRunner 60s linear 0s infinite;
    }

    &.--finished {

      .quiz__content-title,
      .quiz__content-item,
      .quiz__employer {
        display: none;
      }
}
}
@keyframes progressRunner {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 50rem 0;
  }
}